<!--
 TODO

 - change local should reload all the app to make sure everything inits properly

-->
<template>
  <div class="locale-switcher">
    <!--
    <select :value="$i18n.locale" @change.prevent="changeLocale">
      <option :value="locale.code" v-for="locale in locales" :key="locale.code">{{locale.name}}</option>
    </select>
    -->
      <v-img contain v-on:click.native="jumpToLang('/en')" src="/img/en_flag.png" class="mr-1" max-height="30" max-width="80"></v-img>
      <v-img contain v-on:click.native="jumpToLang('/hu')" src="/img/hu_flag.png" class="mr-1" max-height="30" max-width="80"></v-img>
  </div>
  
</template>

<script>
import { getSupportedLocales } from "@/util/i18n/supported-locales"

export default {
  data: () => ({ locales: getSupportedLocales() }),
  methods: {
    changeLocale(e) {
      const locale = e.target.value

      this.$router.push(`/${locale}`)
    },
    jumpToLang (langcode) {
      // console.log(langcode)
      window.location.href = langcode
    }
  }
}
</script>