<template>
  <v-dialog
        v-model="dialog"
        width="70%"
        v-if="item !== null"
        style='z-index:5001;'
        scrollable
        @input="whenClosed()"
        :fullscreen="$vuetify.breakpoint.mobile"
      >
        <template v-slot:activator="{ on }">

          <template v-if="inlineTextLink">
            <template v-if="text">
              <span v-on="on" class="primary--text it-is-link">{{ text }}</span>
            </template>
            <template v-else>
              <span v-on="on" class="primary--text it-is-link">{{ item.item_id }}</span>
            </template>
          </template>
          <template v-else>
            <template v-if="item.type === 700">
              <v-img
                :src="imgResized(item.media_image_file)"
                :width="width"
                v-on="on"
                v-if="item.media_image_file"
                :aspect-ratio="4/3"
              ></v-img>
              <v-img
                :src="photocard_missing"
                :width="width"
                v-on="on"
                :aspect-ratio="4/3"
                v-else
              ></v-img>
            </template>
            <div v-on="on" >

              <div class="max2lines">
                {{ item.name }}
              </div>

            </div>
          </template>
        </template>

        <v-card >

          <v-card-title
            class="headline grey lighten-2"
            primary-title
          >
            {{ item.name }}
            <span v-if="typeof (item._locations_tree) !== 'undefined'">
              / {{ taxonomiesToString(item._locations_tree) }}
            </span>
            <span v-if="item.date_of_collecting">
              / {{ item.date_of_collecting }}
            </span>
            <span v-else-if="item.date_of_collecting_text">
              / {{ item.date_of_collecting_text }}
            </span>

          </v-card-title>

          <v-card-text class="pt-3">

            <!-- song start -->
            <template v-if="item.type === 700">

                <v-row>
                  <v-col cols="12" md="6">
                    <v-img
                      :src="imgResized(item.media_image_file, {'width': 800, 'height': 800})"
                      v-if="item.media_image_file"
                    ></v-img>
                    <v-img
                      :src="photocard_missing"
                      v-else
                    ></v-img>

                    <div v-if="item.media_audio_mp3" style="width: 100%">
                      <br/>
                      <audio controls style="width: 100%" :id="audio_tag_id">
                        <source :src="audio" >
                      </audio>
                    </div>

                  </v-col>
                  <v-col cols="12" md="6">

                    <div style="overflow: auto; max-height: 70vh">
                      <div class="title mb-2 ">{{ ucfirst(item.name) }}</div>

                      <div v-if="typeof (item._locations_tree) !== 'undefined'">
                        <b>{{ $t('location') }}</b>: {{ taxonomiesToString(item._locations_tree) }}
                      </div>

                      <div v-if="Array.isArray(item.sources) && item.sources.length">
                        <b>{{ $t('sources') }}:</b>

                        <span v-for="(source, index) in item.sources" :key="source.id">
                          {{ source.full_name }}<span v-if="index < (item.sources.length - 1)">,</span>
                        </span>
                      </div>

                      <div v-if="item.date_of_collecting">
                        <b>{{ $t('date_of_collecting') }}</b>: {{ item.date_of_collecting }}
                      </div>
                      <div v-else-if="item.date_of_collecting_text">
                        <b>{{ $t('date_of_collecting') }}</b>: {{ item.date_of_collecting_text }}
                      </div>

                      <div v-if="Array.isArray(item.collectors) && item.collectors.length">
                        <b >{{ $t('collector') }}:</b>

                        <span v-for="(collector, index) in item.collectors" :key="collector.id">
                          {{ collector.full_name }}<span v-if="index < (item.collectors.length - 1)">; </span>
                        </span>
                      </div>

                      <div v-if="item.collector_comment">
                        <b>{{ $t('comment') }}</b>: <span v-html="item.collector_comment"></span>
                      </div>

                      <div v-if="typeof (item.taxonomy_1) !== 'undefined'">
                        <b>{{ $t('type') }}</b>: {{ taxonomiesToString(item.taxonomy_1) }}
                      </div>

                      <div v-if="typeof (item.taxonomy_2) !== 'undefined'">
                        <b>{{ $t('presentation_technique') }}</b>: {{ taxonomiesToString(item.taxonomy_2) }}
                      </div>

                      <div v-if="item.lyrics">
                        <br/>
                        <b>{{ $t('lyrics') }}</b>
                        <div class="lyrics" v-html="item.lyrics"></div>
                      </div>                      
                    </div>




                  </v-col>
                </v-row>

            </template>
            <!-- song end -->

          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn
              color="primary"
              @click="dialog = false; whenClosed()"
            >
              {{ $t('close') }}
            </v-btn>
          </v-card-actions>

        </v-card>
      </v-dialog>
</template>

<script>
  import { ES_INDEX } from '@/constants/woa'
  import axios from 'axios'

  export default {
    data () {
      return {
        dialog: false,
        should_cycle: true,
        item: null
      }
    },
    computed: {
      yt_thumbnail () {
        if (this.item.media_image_file) {
          return this.imgResized(this.item.media_image_file, {'width': 600})
        } else {
          return 'https://img.youtube.com/vi/' + this.item.media_video_youtube_code + '/0.jpg'
        }
      },
      audio_tag_id () {
        return 'audio_' + this.item.id
      },
      audio () {
        var ret = ''
        if (this.item.media_audio_mp3) {
          ret = process.env.VUE_APP_MEDIA_WEBROOT + this.item.media_audio_mp3
        }
        return ret
      },
      photocard_missing () {
        if (this.$i18n.locale === 'en') {
          return 'https://via.placeholder.com/600x421?text=The+photo+is+not+available'
        }
        return 'https://via.placeholder.com/600x421?text=A+fotó+nem+érhető+el'
      }
    },
    methods: {
      whenClosed () {
        // stop audio playback when dialog is closed
        var player = document.getElementById('audio_' + this.item.id);
        if (typeof player !== 'undefined' && player !== null && !this.dialog) {
          player.pause();
        }
      }
    },
    mounted () {
      if (this.itemObject) {
        this.item = this.itemObject
      } else if (this.id && this.type) {
        // console.log('fetching from ES')
        /*
        const query = {
          query: {
            match: {
              'id': this.id
            }
          }
        }
        */

        let query = {
          query: {
            match: {
              id: this.id,
            },
          },
        };

        if (isNaN(this.id)) {
          query = {
            query: {
              match: {
                item_id: this.id,
              },
            },
          };
        }
        // console.log(query)

        if (typeof (ES_INDEX[this.type][this.$i18n.locale]) !== 'undefined') {
          const esIndex = ES_INDEX[this.type][this.$i18n.locale]
          axios.get(process.env.VUE_APP_ELASTIC_ENDPOINT + '/' + esIndex + '/_search', {
            params: {
              source: JSON.stringify(query),
              source_content_type: 'application/json'
            },
            auth: {
              username: process.env.VUE_APP_ES_AUTH_USERNAME,
              password: process.env.VUE_APP_ES_AUTH_PASSWORD
            }
          }).then((res) => {
            if (res.status === 200) {
              if (typeof (res.data.hits.total) !== 'undefined' && res.data.hits.total.value === 1) {
                this.item = res.data.hits.hits[0]._source
              }
            }
          })
        }
      }
    },
    name: 'WoaDialog',
    props: ['itemObject', 'lang', 'width', 'id', 'type', 'inlineTextLink', 'text']
  }
</script>

<style>
.yt_thumbnail :hover {
  cursor: pointer;
}
.v-card__title .v-image, .v-card__title .max2lines {
  cursor: pointer;
}
</style>


