<!--
 TODO

 - make the links inside v-tab fill the tab completely
 - rework LocaleSwitcher

-->

<template>
  <div>
    <v-app-bar
      app
      dark
      v-if="isMobile"
      class="mobileHeader"
    >
          <v-app-bar-nav-icon @click="drawer = !drawer" class="d-flex d-md-none"></v-app-bar-nav-icon>

          <v-toolbar-title style="padding-left: 0"><a href="/" style="text-decoration: none;" class="appname ml-3 mr-5"><span v-html="$t('app.title')"></span></a></v-toolbar-title>

          <div class="flex-grow-1"></div>

          <div>

            <LocaleSwitcher />
            <!--
            <v-img contain v-on:click.native="jumpToLang('/en')" src="/img/en_flag.png" class="mb-2" max-height="20" max-width="50"></v-img>
            <v-img contain v-on:click.native="jumpToLang('/hu')" src="/img/hu_flag.png"  max-height="20" max-width="50"></v-img>
            -->
          </div>

      <!--
      <LocaleSwitcher />
      -->
    </v-app-bar>

    <v-app-bar
      app
      dark
      v-else
    >
      <v-app-bar-nav-icon @click="drawer = !drawer" class="d-flex d-md-none"></v-app-bar-nav-icon>
      <v-toolbar-title>
        <div class="ppMenu">
          <span class="menuItem">
            <a href="/" style="text-decoration: none;">
              <v-img
                  src="/img/polyphony_sima.png"
                  height="48"
                  width="46"
              ></v-img>
            </a>
          </span>
          <template v-for="item in items">
            <span class="menuItem textMenuItem" :key="item.url">
              <LocalizedLink :to="item.url">{{ $t(item.text) }}</LocalizedLink>
            </span>
          </template>
        </div>
      </v-toolbar-title>

      <div class="flex-grow-1"></div>

      <!--
      <v-img contain v-on:click.native="jumpToLang('/en')" src="/img/en_flag.png" class="mr-1 mt-2" max-height="30" max-width="80"></v-img>
      <v-img contain v-on:click.native="jumpToLang('/hu')" src="/img/hu_flag.png" class="mr-1 mt-2" max-height="30" max-width="80"></v-img>
      <v-img contain v-on:click.native="jumpToLang('/uk')" src="/img/uk_flag.png" class="mr-1 mt-2" max-height="30" max-width="80"></v-img>

      <select :value="$i18n.locale" @change.prevent="changeLocale">
        <option :value="locale.code" v-for="locale in locales" :key="locale.code">{{locale.name}}</option>
      </select>
      -->

      <LocaleSwitcher />
    </v-app-bar>


    <v-navigation-drawer
      v-model="drawer"
      app
      temporary
    >
        <div class="align-center">
          <v-list
            dense
          >
            <template v-for="(item, i) in items">
              <v-list-item 
                v-if="isExternal(item.url)" 
                :key="i"
              >
                <a :href="item.url[$i18n.locale]" target="_blank">
                <v-list-item-content>
                  <v-list-item-title class="text-white">
                    {{ $t(item.text) }}
                  </v-list-item-title>
                </v-list-item-content>
                </a>
              </v-list-item>

              <v-list-item
                v-else
                :key="i"
              >
                  <LocalizedLink :to="item.url">
                    <v-list-item-content>
                      <v-list-item-title class="text-white">
                        {{ $t(item.text) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </LocalizedLink>
              </v-list-item>
            </template>

          </v-list>
        </div>

    </v-navigation-drawer>

  </div>
</template>

<script>
import LocaleSwitcher from "@/components/LocaleSwitcher"
import LocalizedLink from "@/components/LocalizedLink"

export default {
  data: () => ({
    drawer: false,
    items: [
      { text: 'nav.about', url: 'about' },
      { text: 'nav.records', url: 'records' }
      /*
      { text: 'nav.map', url: 'map' },
      { text: 'nav.about', url: 'page', params: {hu: {slug: 'rolunk'}, en: {slug: 'about'}} }
      */
    ]
  }),
  methods: {
    jumpToLang (langcode) {
      window.location.href = langcode
    },
    isExternal (url) {
      return typeof url.hu !== 'undefined'
    }
  },
  computed: {
    isMobile () {
      var ret = false
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          ret = true; break
        case 'sm':
          ret = true; break
      }
      return ret
    },
  },
  components: { 
    LocaleSwitcher, 
    LocalizedLink 
  }
}
</script>

<style scoped>
.ppMenu {
  display: flex;
  justify-content: space-around;
}
.ppMenu .menuItem {
  height: 48px;
  line-height: 48px;
}
.ppMenu .textMenuItem {
  padding-left: 20px;
}
.ppMenu .textMenuItem a {
  color: white;
  text-decoration: none;
}
.mobileHeader a.appname {
  font-size: 20px;
  color: white !important;
  font-weight: 700;
}
.v-app-bar.v-app-bar--fixed {
  z-index: 1000;
}
.v-navigation-drawer {
  z-index: 1001;
}
</style>
