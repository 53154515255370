<template>
  <div>
    <v-container>
      <page :page="page" :lang="this.$i18n.locale" v-if="page"> </page>
    </v-container>
  </div>
</template>

<script>
import Page from "@/components/Page";
import { PAGE_QUERY } from "@/constants/graphql";

export default {
  name: "Home",
  data() {
    return {
      page: null,
      lang: this.$i18n.locale
    };
  },
  components: {
    Page
  },
  apollo: {
    page: {
      query: PAGE_QUERY,
      variables() {
        return {
          slug: "home",
          lang: this.$i18n.locale,
        };
      },
    },
  },
};
</script>

