<template>
    <div v-if="content.length">
        <div v-for="block in content" :key="block.contents">
            <template v-if='block.type === "100"'>
                <div v-html="block.contents"></div>
            </template>
        </div>
    </div>
</template>


<script>
  export default {
    name: 'Page',
    computed: {
      content () {
        var ret = []
        if (typeof this.page.content_blocks !== 'undefined') {
          var obj = JSON.parse(this.page.content_blocks)
          for (var item in obj) {
            ret.push({type: obj[item].type, contents: obj[item].contents})
          }
        }
        // console.log(ret)
        return ret
      }
    },
    props: ['page', 'lang']
  }
</script>
